import React, { useContext } from "react";
import { Link } from "gatsby";
import ThemeContext from "../../stores/ThemeContext";

const pagesEN = ["/", "/approach", "/services", "/about", "/contact"];
const pagesCZ = ["/cz", "/cz/jak-pracujeme", "/cz/co-umime", "/cz/o-nas", "/cz/kontakt"];
const removeTrailingSlash = (pathName) => {
    if (pathName.lastIndexOf("/") === pathName.length - 1) {
        const newPathName = pathName.substring(0, pathName.length - 1);
        // console.log("PathName changed!", newPathName)
        return newPathName;
    } else {
        return pathName;
    }
};

const Navbar = (props) => {
    let { themeOpt } = useContext(ThemeContext);
    //  console.log(themeOpt);
    const switchToEnglish = (ev) => {
        ev.preventDefault();
        const savePathName = removeTrailingSlash(window.location.pathname);
        const pageIndex = pagesCZ.indexOf(savePathName);
        // console.log(pageIndex)
        if (pageIndex > -1) {
            window.location.pathname = pagesEN[pageIndex];
        } else {
            window.location.pathname = pagesEN[0];
        }
    };
    const switchToCzech = (ev) => {
        ev.preventDefault();
        const savePathName = removeTrailingSlash(window.location.pathname);
        const pageIndex = pagesEN.indexOf(savePathName);
        // console.log(pageIndex)
        if (pageIndex > -1) {
            window.location.pathname = pagesCZ[pageIndex];
        } else {
            window.location.pathname = pagesCZ[0];
        }
    };
    return (
        <>
            {themeOpt.lang === "cz" && (
                <nav>
                    <ul className={`menu${props.className ? props.className : ""}`}>
                        <li>
                            <Link to="/cz/jak-pracujeme" activeClassName="active">
                                jak pracujeme
                            </Link>
                        </li>
                        <li>
                            <Link to="/cz/co-umime" activeClassName="active">
                                co umíme
                            </Link>
                        </li>
                        <li>
                            <Link to="/cz/o-nas" activeClassName="active">
                                o nás
                            </Link>
                        </li>
                        <li>
                            <Link to="/cz/kontakt" activeClassName="active">
                                kontakt
                            </Link>
                        </li>
                        <li>-------</li>
                        <li>
                            <a href="/" onClick={switchToEnglish}>
                                english
                            </a>
                        </li>
                    </ul>
                </nav>
            )}
            {themeOpt.lang === "en" && (
                <nav>
                    <ul className={`menu${props.className ? props.className : ""}`}>
                        <li>
                            <Link to="/approach" activeClassName="active">
                                Approach
                            </Link>
                        </li>
                        <li>
                            <Link to="/services" activeClassName="active">
                                Services
                            </Link>
                        </li>
                        <li>
                            <Link to="/about" activeClassName="active">
                                About
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact" activeClassName="active">
                                Contact
                            </Link>
                        </li>
                        <li>-------</li>
                        <li>
                            <a href="/cz/" onClick={switchToCzech}>
                                Česky
                            </a>
                        </li>
                    </ul>
                </nav>
            )}
        </>
    );
};
export default Navbar;
